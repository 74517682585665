<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndUp" style="min-height:90vh;" class="pa-0">
    <v-card class="mt-3 fill-height" style="min-height: 85vh" v-show="!loading" color="transparent">
      <v-card-title class="white">
        <v-btn :to="{name:'Home'}" icon class="mr-3">
          <pg-icon XL>i-go-back</pg-icon>
        </v-btn>&nbsp;<span v-if="selectedGameType">{{ selectedGameType.name }}</span>
        <span v-else>Mes Favoris</span>
      </v-card-title>
      <v-card-text class="pa-0">
        <game-filter class="mb-1" v-model="selectedGameFilters" v-on:refresh="loadGamesByTypes"/>
        <v-row no-gutters v-if="gamesByType.length">
          <v-col cols="12" sm="6" v-for="game in gamesByType" v-bind:key="game.id" class="pa-1">
            <GameCard :game="game" class="mb-2"></GameCard>
          </v-col>
        </v-row>
        <v-row no-gutters v-else justify="center" align="center" style="min-height: 75vh">
          <v-card max-width="100%" class="mt-5" flat>
            <v-card-title class="text-center">Il n'y a pas encore de jeux pour votre sélection. Mais cela ne saurait
              tarder...
            </v-card-title>
            <v-img height="500" src="~@/assets/oops.png" contain max-width="1280px"></v-img>
          </v-card>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row align="center" justify="center">
      <v-progress-circular class="ma-auto" indeterminate color="purple" size="50" v-if="loading"/>
    </v-row>
    <game-pagination class="mt-12"/>
  </v-container>
</template>

<script>
import GameCard from "../../components/game/GameCard";
import GameFilter from "../../components/game/GameFilter";
import PgIcon from "../../components/PGIcon";
import GameMixin from "../../mixins/GameMixin";
import GamePagination from "../../components/game/GamePagination";

export default {
  name: "ShowType",
  components: {GamePagination, PgIcon, GameFilter, GameCard},
  mixins: [GameMixin],
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    selectedGameType() {
      return this.gameTypes.find(type => type.code === this.$route.params.typeCode);
    }
  },
  created() {
    this.loadGames();
  },
  methods: {
    async loadGames() {
      if (!this.loading) {
        this.loading = true;
        if (!this.gameTypes.length) {
          await this.getGameTypes();
        }
        this.loadGamesByTypes().finally(() => {
          setInterval(() =>{
            this.loading = false;
          },300)
        })
      }
    }
  },
  watch: {
    $route: function () {
      if (this.$route.name === 'GameType') {
        this.loadGames()
      }
    }
    ,
    selectedGameFilters: function () {
      this.loadGamesByTypes();
    }
    ,
    selectedGameType: function () {
      this.loadGamesByTypes();
    }

  }
}
</script>

<style scoped>

</style>