<template>
  <v-card elevation="5">
    <v-card-title class="pt-0 pb-0">
      <h4 class="title" style="max-width: 70%">{{ game.title }}</h4>
      <v-spacer/>
      <GameFavoriteButton class="mr-2" icon v-model="game"/>
      <v-btn icon style="max-width: 25%">
        <b>{{ game.pointsReward }}</b>
        <pg-icon>i-pimentos</pg-icon>
      </v-btn>
    </v-card-title>
    <v-img height="175" :src="getGameImageUrl(game)" v-if="!isCommunity"/>
    <v-row v-else no-gutters style="max-width: 98%">
      <v-col cols="12" class="subtitle-1 mt-3 mb-3 ml-1 mr-1 not-more-than-3-lines"
             v-html="isCommunity?game.descriptionLong:game.descriptionShort"/>
    </v-row>
    <v-card-text class="pa-1">
      <v-row no-gutters class="mt-2">
        <pg-icon v-for="(categoryElement,n) in game.categoryElements" v-bind:key="n">{{
            categoryElement.icon
          }}
        </pg-icon>
        <v-divider class="transparent"/>
        <span class="caption">Joué {{ game.playedCounter }} fois</span>
      </v-row>
      <v-row justify="center" align="center" no-gutters>
        <v-col cols="8" v-if="isCommunity">
          <v-row justify="start" align="center" no-gutters>
            <v-col cols="12" class="">
              <router-link :to="{name:'Profile',params:{player:game.author.id}}" class="caption text-decoration-none ml-2">
                <v-avatar class="" size="30">
                  <v-img :src=" getAvatarUrlForPlayer(game.author)" height="30px" contain/>
                </v-avatar>
                {{ game.author.nickName }}
              </router-link>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-else cols="8" class="not-more-than-3-lines" v-html="!isCommunity?game.descriptionShort:''"/>
        <v-col cols="4" :class="isCommunity?'ml-auto':''" >
          <v-btn class="pg-hot-gradient" large @click="gameToStart = game" dark style="width: 100%">
            <pg-icon XL>i-play</pg-icon>
            <jumping-pimentos v-if="game.pointsCost "/>
            <span v-if="game.pointsCost "><b>{{ game.pointsCost }}</b></span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import PgIcon from "@/components/PGIcon";
import GameMixin from "../../mixins/GameMixin";
import GameFavoriteButton from "./GameFavoriteButton";
import JumpingPimentos from "../JumpingPimentos";
import AvatarMixin from "../../mixins/AvatarMixin";

export default {
  name: "GameCard",
  components: {JumpingPimentos, GameFavoriteButton, PgIcon},
  props: ["game"],
  mixins: [GameMixin, AvatarMixin],
  data: () => ({}),
  computed: {
    isCommunity() {
      return this.game.author != null;
    }
  }
}
</script>

<style scoped>
.not-more-than-3-lines {
  line-height: 1.4em;
  height: 4.4em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 95%;
}

</style>