<template>
  <div class="text-center mt-4 mb-4">
    <v-pagination circle :total-visible="totalGames"
                  v-model="gamePage"
                  :length="Math.ceil(totalGames/gamesPerPage)"
    ></v-pagination>
    <v-select
        v-model="gamesPerPage"
        :items="items"
        label="Nombre de jeux par page"
        :full-width="false"
        rounded
        dark
        class="pg-hot-gradient"
    />
  </div>
</template>

<script>
import GameMixin from "../../mixins/GameMixin";

export default {
  name: "GamePagination",
  mixins: [GameMixin],
  data() {
    return {
      items: [5, 10, 15, 20, 50]
    }
  },
  watch: {
    'gamePage': function () {
      this.loadGamesByTypes();
    },
    'gamesPerPage': function () {
      this.loadGamesByTypes();
    }
  }
}
</script>

<style scoped>

</style>